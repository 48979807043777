import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import MakeRow from './RowContent';

const PdfFormat = () => {
  const [resumeData, setResumeData] = useState({});
  const { heading, projects, workExperience, profile, education } = resumeData;
  const span = projects?.data?.length + 1 + workExperience?.data?.length + 1 + education?.data?.length + 1 + 2;

  const resumeID = useParams();

  useEffect(() => {
    fetch('https://build-resume-support.herokuapp.com/resume-data')
      .then((res) => res.json())
      .then((result) => {
        const resume = result?.data?.resumeData.find((item) => item['_id'] === resumeID.id);
        setResumeData({ ...resume } || {});
      })
      .catch((err) => {
        console.error('There was an error: ' + err.message);
      });
  }, [resumeID]);

  return (
    <Card className='main-card'>
      <CardBody style={{ padding: ' 0' }}>
        <Row>
          <Col>
            <Table borderless>
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <h1 style={{ fontSize: '2em' }}>
                        <b>{heading?.data[0]?.name}</b>
                      </h1>
                      <div style={{ marginBottom: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1em', color: '#4f6773' }}>
                        <span>
                          {heading?.data[0]?.email} | {heading?.data[0]?.phone}
                        </span>
                        <span>{heading?.data[0]?.address}</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <MakeRow colData={profile} span={span} />
                <MakeRow colData={education} span={span} />
                <MakeRow colData={workExperience} span={span} />
                <MakeRow colData={projects} span={span} />
              </tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default PdfFormat;
