import React from 'react';
import SkillsContainer from './SkillsContainer';
import { FcGraduationCap } from 'react-icons/fc';
import { BsFillBriefcaseFill } from 'react-icons/bs';
import { BsFillPersonFill } from 'react-icons/bs';
import { BsStarFill } from 'react-icons/bs';
import { BsFillCircleFill } from 'react-icons/bs';
import { AiOutlineDash } from 'react-icons/ai';
import './style.css';

const mainColStyle = {
  fontSize: '1.31125em',
  fontWeight: 'bold',
  margin: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center'
};

const topicStyle = {
  fontSize: '1em',
  fontWeight: 'bold',
  margin: 0
};

const descriptionStyle = {
  fontSize: '.875em',
  color: '#10353c',
  margin: 0,
  textAlign: 'justify'
};

const dateStyle = {
  fontSize: '.875em',
  color: '#697b7f',
  margin: 0
};

const MakeRow = ({ colData, span }) => {
  const Icon = () => {
    if (colData?.name === 'Profile') return <BsFillPersonFill size={'2rem'} />;
    if (colData?.name === 'Education') return <FcGraduationCap size={'2rem'} />;
    if (colData?.name === 'Work Experience') return <BsFillBriefcaseFill size={'2rem'} />;
    if (colData?.name === 'Projects') return <BsStarFill size={'2rem'} />;
    return null;
  };

  const getNameAndDate = (topic, item) => {
    let Name = () => '';
    let date = '';

    switch (topic) {
      case 'Work Experience':
        Name = () => (
          <h3 style={topicStyle}>
            {item?.role}, at {item?.company}
          </h3>
        );
        date = item?.date;
        break;
      case 'Projects':
        Name = () => (
          <h3 style={topicStyle}>
            {item?.name}{' '}
            <a style={dateStyle} href={`${item?.link}`} target='_blank' rel='noopener noreferrer'>
              {item?.link}
            </a>
          </h3>
        );
        date = item?.date;
        break;
      case 'Education':
        Name = () => (
          <h3 style={topicStyle}>
            {item?.course}, at {item?.graduatedFrom}
          </h3>
        );
        date = item?.graduatedDate;
        break;
      default:
        Name = () => '';
        date = '';
    }

    return { Name, date };
  };

  return (
    <React.Fragment>
      <tr>
        {colData?.name === 'Profile' && (
          <td width='25%' rowSpan={span}>
            <SkillsContainer topicStyle={topicStyle} />
          </td>
        )}
        <td width='5%'>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Icon />
          </div>
        </td>
        <td width='70%'>
          <h2 style={mainColStyle}> {colData?.name}</h2>
        </td>
      </tr>
      {colData?.data.map((item, i) => {
        const { Name, date } = getNameAndDate(colData?.name, item);
        return (
          <tr key={i}>
            <td style={{ overflow: 'hidden', position: 'relative', paddingTop: i === 0 && 0 }}>
              {colData?.name === 'Projects' && (
                <>
                  <div
                    style={{
                      width: '100%',
                      position: 'absolute',
                      left: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '3px'
                    }}
                  >
                    <BsFillCircleFill size={'.8rem'} />
                    <AiOutlineDash size={'3rem'} className={'transform90'} />
                    <AiOutlineDash size={'3rem'} className={'transform90'} />
                    <AiOutlineDash size={'3rem'} className={'transform90'} />
                    <AiOutlineDash size={'3rem'} className={'transform90'} />
                    <AiOutlineDash size={'3rem'} className={'transform90'} />
                  </div>
                  <div style={{ width: '100%', height: '1rem', position: 'absolute', bottom: 0, left: 0, background: 'white' }}></div>
                </>
              )}
            </td>
            <td style={{ paddingTop: i === 0 && 0 }}>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                {colData?.name !== 'Profile' && (
                  <div style={{ marginBottom: '5px' }}>
                    <Name />
                    {colData?.name === 'Projects' && <p style={dateStyle}>{item?.languages.join(', ').toUpperCase()}</p>}
                    <p style={dateStyle}>{date}</p>
                  </div>
                )}
                <div style={descriptionStyle}>
                  {item?.description && <span>{item?.description}</span>}
                  {item?.tasks && (
                    <ul>
                      {item.tasks.map((task, index) => (
                        <li key={index}>{task}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </td>
          </tr>
        );
      })}
    </React.Fragment>
  );
};

export default MakeRow;
