import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import App from './App';
import ResumeBuilder from './app/ResumeBuilder';
import PdfFormat from './app/ResumeBuilder/resumeFormat';

const NoRoute = () => {
  return (
    <main id='main'>
      <div style={{ padding: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h2>No page found</h2>
      </div>
    </main>
  );
};
const Maintainance = () => {
  return (
    <main id='main'>
      <div style={{ padding: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h2 style={{ textDecoration: 'underline' }}>Site Under Maintainance. Thank you for visiting!</h2>
      </div>
    </main>
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Maintainance />} />
        <Route path='buildpdf' element={<ResumeBuilder />}></Route>
        <Route path='/buildpdf/resume/:id' element={<PdfFormat />} />
        <Route path='*' element={<NoRoute />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
