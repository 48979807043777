import React from 'react';
import skillData from '../../../data/skills.json';
import otherSkills from '../../../data/otherSkills.json';
import ProgressBar from './ProgressBar';

const SkillsContainer = ({ topicStyle }) => {
  return (
    <div style={{ margin: '5px 0' }}>
      <div>
        <h2 style={{ ...topicStyle, textAlign: 'center' }}>Programming Skills</h2>
        <div style={{ padding: '0 10%' }}>
          {skillData?.map((skill, index) => (
            <ProgressBar key={index} value={skill?.level} text={skill?.name} />
          ))}
        </div>
      </div>
      <br />
      <br />
      <div>
        <h2 style={{ ...topicStyle, textAlign: 'center' }}>Other Skills</h2>
        <div style={{ padding: '0 10%' }}>
          {otherSkills?.map((skill, index) => (
            <ProgressBar key={index} value={skill?.level} text={skill?.name} />
          ))}
        </div>
      </div>
      <br />
      <br />
      <div>
        <h2 style={{ ...topicStyle, textAlign: 'center' }}>Links</h2>
        <div style={{ padding: '0 10%', display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '0.875em' }}>
          <a href='https://www.linkedin.com/in/badri-dahal-1a2b44188/' target='_blank' rel='noreferrer noopener' style={{ color: 'black' }}>
            LinkedIn
          </a>
          <a href='https://badridahal.netlify.app/' target='_blank' rel='noreferrer noopener' style={{ color: 'black' }}>
            My Portfolio
          </a>
          <a href='https://github.com/Badree007' target='_blank' rel='noreferrer noopener' style={{ color: 'black' }}>
            Github
          </a>
        </div>
      </div>
      <br />
      <br />
      <div>
        <h2 style={{ ...topicStyle, textAlign: 'center' }}>Courses</h2>
        <div style={{ padding: '0 10%', display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '0.875em', textAlign: 'center' }}>
          <span>JAVASCRIPT, Udemy</span>
          <span>PHP, Udemy</span>
          <span>JAVA, Udemy</span>
          <span>Data Structure, Code with Mosh</span>
        </div>
      </div>
      <br />
      <br />
      <div>
        <h2 style={{ ...topicStyle, textAlign: 'center' }}>Languages</h2>
        <div style={{ padding: '0 10%' }}>
          <ProgressBar value={'75'} text={'English'} />
          <ProgressBar value={'100'} text={'Nepali'} />
          <ProgressBar value={'75'} text={'Hindi'} />
        </div>
      </div>
    </div>
  );
};

export default SkillsContainer;
