import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Form, Row } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import CreateElement from './resumeDataElement';
import { useNavigate } from 'react-router-dom';

const Resume = ({ resumeObj }) => {
  const [resume, setResume] = useState({ ...resumeObj });
  console.log({ resume });

  const navigate = useNavigate();

  const saveResumeData = () => {
    fetch(`https://build-resume-support.herokuapp.com/resume-data/${resume['_id']}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application.json'
      },
      body: JSON.stringify({ ...resume })
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((error) => console.error(error.message));
  };

  const generatePDF = () => {
    if (!resume || !resume['_id']) return;

    navigate(`/buildpdf/resume/${resume['_id']}`);
  };

  return (
    <div key={resume['_id']} className='resume-body'>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '1rem', gap: '5px' }}>
        <Button onClick={saveResumeData}>Save Data</Button>
        <Button onClick={generatePDF}>Generate PDF</Button>
      </div>
      <Card style={{ margin: '1rem', background: 'none' }}>
        <CardBody>
          <Form>
            <Row>
              {Object.values(resume)
                .filter((item) => item !== resume['_id'])
                .map((topic, index) => (
                  <CreateElement key={index} id={resume['_id']} heading={topic} setData={setResume} />
                ))}
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

const ResumeBuilder = () => {
  const [resumeData, setResumeData] = useState([]);
  console.log({ resumeData });

  useEffect(() => {
    fetch('https://build-resume-support.herokuapp.com/resume-data')
      .then((res) => res.json())
      .then((result) => {
        setResumeData([...result?.data?.resumeData] || {});
      })
      .catch((err) => {
        console.error('There was an error: ' + err.message);
      });
  }, []);

  return (
    <section className='resume'>
      <div style={{ padding: '3% 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className='div-box' style={{ width: 'min(1400px, 95%)' }}>
          <h1 style={{ width: '100%', textAlign: 'center', marginTop: '5px' }}>Build Resume</h1>
          {!!resumeData?.length && resumeData.map((resume) => <Resume key={resume['_id']} resumeObj={resume} />)}
        </div>
      </div>
    </section>
  );
};

export default ResumeBuilder;
