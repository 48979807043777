import React from 'react';

const ProgressBar = ({ text, value }) => {
  return (
    <div style={{ padding: '5px' }}>
      <h5 style={{ textAlign: 'center', fontSize: '0.875em', margin: '0' }}>{text}</h5>
      <div style={{ width: '100%', height: '8px', backgroundColor: '#b7c2c3e6', borderRadius: '12px', border: '1px solid #f2ecece6' }}>
        <div style={{ height: '100%', width: `${value}%`, backgroundColor: '#063c55', borderRadius: 'inherit' }}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
