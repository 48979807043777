import React from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { FiDelete } from 'react-icons/fi';

const CreateElement = ({ heading, setData }) => {
  const addData = () => {
    const dataFormat = Object.keys(heading?.format).reduce((res, val) => {
      if (!res) res = {};
      res[val] = val === 'tasks' ? [''] : '';
      return res;
    }, {});

    setData((prev) => {
      return { ...prev, [heading.value]: { ...prev[heading.value], data: [...prev[heading.value].data, dataFormat] } };
    });
  };

  const addTaskList = (atIndex) => {
    setData((prev) => {
      const changedData = [...prev[heading.value]?.data];
      const taskList = changedData[atIndex]['tasks'];
      taskList.push('');
      changedData[atIndex] = { ...changedData[atIndex], tasks: [...taskList] };

      return { ...prev, [heading.value]: { ...prev[heading.value], data: [...changedData] } };
    });
  };

  const deleteTask = (id, taskIndex) => {
    setData((prev) => {
      let changedData = [...prev[heading.value]?.data];
      let mainData = changedData.find((item) => item['_id'] === id);
      const taskList = mainData['tasks'];
      taskList.splice(taskIndex, 1);
      mainData = { ...mainData, tasks: [...taskList] };
      changedData = changedData.map((data) => {
        if (data['_id'] === id) return mainData;
        return data;
      });
      return { ...prev, [heading.value]: { ...prev[heading.value], data: [...changedData] } };
    });
  };

  return (
    <Col xs='12' sm='6' style={{ padding: '12px' }}>
      <Col xs='12'>
        <h2>{heading?.name}</h2>
      </Col>
      <Col xs='12'>
        {heading?.data?.map((keyField, index) => (
          <Row key={index} style={{ borderRadius: '8px', boxShadow: '0 0 5px 0 #00000050, 0 2px 10px 0 #00000050', marginBottom: '10px' }}>
            {Object.values(heading?.format || []).map((item, i) => (
              <Col xs='12' key={i}>
                <FormGroup>
                  <Label>{item?.name} </Label>
                  {item?.name === 'Task' ? (
                    keyField?.[item?.value]?.map((task, taskIndex) => (
                      <div key={taskIndex} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <Input
                          style={{ height: '50px', fontSize: '1.3rem' }}
                          type={item?.type}
                          value={task}
                          onChange={(e) => {
                            setData((prev) => {
                              const changedData = [...prev[heading.value]?.data];
                              const taskList = changedData[index][item?.value];
                              taskList[taskIndex] = e.target.value;
                              changedData[index] = { ...changedData[index], [item?.value]: [...taskList] };

                              return { ...prev, [heading.value]: { ...prev[heading.value], data: [...changedData] } };
                            });
                          }}
                        ></Input>
                        <FiDelete style={{ cursor: 'pointer' }} onClick={() => deleteTask(keyField['_id'], taskIndex)} />
                      </div>
                    ))
                  ) : (
                    <Input
                      style={{ minHeight: item?.type === 'textarea' ? '120px' : '100%', fontSize: '1.3rem' }}
                      type={item?.type}
                      value={keyField[item?.value]}
                      onChange={(e) => {
                        setData((prev) => {
                          const changedData = [...prev[heading.value]?.data];
                          changedData[index] = { ...changedData[index], [item?.value]: e.target.value };

                          return { ...prev, [heading.value]: { ...prev[heading.value], data: [...changedData] } };
                        });
                      }}
                    ></Input>
                  )}
                  {item?.name === 'Task' && (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '10px 0' }}>
                      <Button onClick={() => addTaskList(index)}>Add Task</Button>
                    </div>
                  )}
                </FormGroup>
              </Col>
            ))}
          </Row>
        ))}
      </Col>
      {heading?.name !== 'Profile' && heading?.name !== 'Heading' && (
        <Col xs='6'>
          <Button onClick={addData}>Add</Button>
        </Col>
      )}
    </Col>
  );
};

export default CreateElement;
